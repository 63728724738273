import { requisitesApi } from 'app/services/requisites';
import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../app/store'
import { IHashtag, ITag } from 'app/interfaces/requisites'

type RequisitesState = {
  hashtags: IHashtag[],
  tags: ITag[];
}

const initialState = {
  hashtags: [],
  tags: []
}

const slice = createSlice({
  name: 'requisites',
  initialState: initialState as RequisitesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        requisitesApi.endpoints.getHashtags.matchFulfilled,
        (state, { payload }) => {
          state.hashtags = payload
        }
      )
      .addMatcher(
        requisitesApi.endpoints.getTagsDishes.matchFulfilled,
        (state, { payload }) => {
          state.hashtags = payload
        }
      )
  },
})

export const { } = slice.actions // eslint-disable-line

export default slice.reducer

export const selectHashtags = (state: RootState) => state.requisites.hashtags
export const selectTagsDishes = ( state: RootState ) => state.requisites.tags