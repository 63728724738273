import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { 
  selectAllRestaurants, 
  selectPaginationRestaurants, 
  selectFiltersRestaurants 
} from 'features/restaurants/slice'

export const useRestaurants = () => {
  const restaurants = useSelector(selectAllRestaurants)
  const filter = useSelector(selectFiltersRestaurants)
  const pagination = useSelector(selectPaginationRestaurants)

  return useMemo(() => ({ restaurants, filter, pagination }), [restaurants, filter, pagination])
}