import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { showNorr } from 'features/norr/slice'
// import { toast } from 'your-cool-library'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      api.dispatch(showNorr({severity: "warn", message: action.payload.data.message}))
      // console.log(action.payload.data.message, api.getState())
      // console.warn(action.payload.data.message)
      // toast.warn({ title: 'Async error!', message: action.error.data.message })
    }

    return next(action)
  }