import { api } from './api'

export const citiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCities: build.query({
      query: () => ({ url: '/cities' }),
      providesTags: () => [
        { type: 'Cities' },
      ],
    })
  }),
})

export const { useGetCitiesQuery } = citiesApi;