import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
// import SingleUploadImage from "components/upload/single.upload";
import { IRestaurant } from "app/interfaces/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";
import { GalleriaImage } from "./gellery.image";
import GalleryForm from "./gellery.form";
import { useUploadGalleryMutation } from "app/services/restaurants";

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const GalleryEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [files, setFiles] = useState<any>([]);
  const [upload] = useUploadGalleryMutation();
  let refGallery = useRef<any>(null);
  
  const handleSave = () => {
    upload({restaurantId: restaurant.id, gallery: files}).unwrap()
      .then( () => setPage('menu') )
  }

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Галерея </h4>
        <ScrollPanel style={{maxHeight: '500px'}}>

          <h3 className="p-m-2">Текущая галерея</h3>
          <GalleriaImage restaurant={restaurant} />
                      
          <h3 className="p-m-2">Новые фотографии</h3>
          <GalleryForm setFiles={setFiles} files={files} ref={refGallery}/>
        </ScrollPanel>

        <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
          <div className="col-12 md:col-6 xl:col-3">
            <Button label="Сохранить" className="col-12 p-button-raised p-button-success " onClick={handleSave}/>
          </div>
        </div>
      </div>
    </div>  
  );
}

export default GalleryEdit;
