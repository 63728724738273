import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Divider } from 'primereact/divider';
import { IPhone, IRestaurant } from "app/interfaces/restaurants";
import { Field, Form } from "react-final-form";
import { composeValidators, phoneValidator, requiredValidator } from "components/form/validators";
import { MaskField, TextField } from "components/form/fields";
import AddingPhones from "./phones.edit";
import { useUpdateRestaurantContactsMutation } from "app/services/restaurants";

const formatResponse = (data: any, phones: any[]) => {
  return {
    restaurantId: data.id,
    telephone: data.telephone ? data.telephone : null,
    phoneOwner: data.addInfo?.phoneOwner ? data.addInfo.phoneOwner : null,
    phoneComment: data.addInfo?.phoneComment ? data.addInfo.phoneComment : null,
    addPhone: JSON.stringify(phones)
  }
}

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const ContactsEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [ phones, setPhone ] = useState<IPhone[]>([]);
  const [ updateRestaurant ] = useUpdateRestaurantContactsMutation();

  useEffect(()=> { 
    setPhone(restaurant?.addPhones ? restaurant.addPhones : []) 
  }, [restaurant])

  const onSubmit = (values: any) => {
    updateRestaurant( formatResponse(values, phones) ).unwrap()
      .then( () => setPage("menu") )
  };

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Контакты </h4>

        <Form
          onSubmit={onSubmit}
          initialValues={restaurant}
          render={({ handleSubmit }) => (
            
              <form onSubmit={handleSubmit} >
                
                <div className="col-12 md:col-6 pt-1 pb-1">
                  <Field
                    validate={composeValidators(phoneValidator)}
                    name="telephone"
                    label="Телефон заведения:"
                    render={MaskField}
                    settings={{
                      placeholder: "Введите номер телефона заведения",
                      mask: "+00000000000",
                    }}
                  />
                </div>

                <Divider className="mt-1 mb-1"/>

                <div className="col-12 p-0 flex flex-wrap">
                  <div className="col-12 md:col-6 pt-1 pb-1">
                    <Field
                      validate={composeValidators(phoneValidator)}
                      name="addInfo.phoneOwner"
                      label="Телефон владельца:"
                      render={MaskField}
                      settings={{
                        placeholder: "Введите номер телефона владельца",
                        mask: "+00000000000",
                      }}
                    />
                  </div>

                  <div className="col-12 md:col-6 pt-1 pb-1">
                    <Field
                      name="addInfo.phoneComment" label="Комментарий:" render={TextField}
                      settings={{
                        placeholder: "Комментарий к номеру владельца",
                      }}
                    />
                  </div>
                </div>
              
                <AddingPhones phones={phones} setPhone={setPhone}/>

                <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
                  
                  <div className="col-12 md:col-6 xl:col-3">
                    <Button label="Сохранить" className="col-12 p-button-raised p-button-success "/>
                  </div>
                </div>

                

              </form>
          )}
        />
      </div>
    </div>
  );
}

export default ContactsEdit;
