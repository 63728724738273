import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
// import { ProSidebarProvider } from 'react-pro-sidebar';
import Main from './main';

export function PrivateOutlet() {
  const auth = useAuth()
  const location = useLocation()

  return auth.isAuth ? (
    // <ProSidebarProvider>
      <Main />
    // </ProSidebarProvider>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  )
}