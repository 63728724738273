import React from "react";
import { useDispatch } from "react-redux";
import { useWindows } from "hooks/useWindows";

import { Dialog } from "primereact/dialog";
import { hide } from "features/windows/slice";
import { useGetRestaurantsMutation } from "app/services/restaurants";
import { useRestaurants } from "hooks/useRestaurants";
import FiltersContent from "./filters";

export const FiltersWin: React.FC = (props: any) => {
  const dispatch = useDispatch()
  const windows = useWindows()

  const [ getRestaurants ] = useGetRestaurantsMutation()
  const { filter, pagination } = useRestaurants()

  const handleClose = async () => {
    await getRestaurants({filters: filter, pagination })
    dispatch(hide({type: 'restaurants', name: 'filter'}))
  }

  return (
    <Dialog 
      header="Фильтры по заведениям" 
      visible={windows.restaurants.filter} 
      className="col-12 p-0 md:col-10 lg:col-8"
      onHide={handleClose}
      maximizable={true}
    >
      <FiltersContent />
    </Dialog>
  );
}

export default FiltersWin;