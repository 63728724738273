import { citiesApi } from 'app/services/cities';
import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../app/store'
import { ICity } from 'app/interfaces/city';

type CitiesState = {
  cities: ICity[],
}

const initialState = {
  cities: [],
}

const slice = createSlice({
  name: 'cities',
  initialState: initialState as CitiesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        citiesApi.endpoints.getCities.matchFulfilled,
        (state, { payload }) => {
          state.cities = payload
        }
      )
  },
})

export const { } = slice.actions // eslint-disable-line

export default slice.reducer

export const selectCities = (state: RootState) => state.cities.cities