import { IFiltersRestaurants, IPagination } from 'app/interfaces/restaurants';
import { api } from './api'
import { ICity } from 'app/interfaces/city';
import { ICategory } from 'app/interfaces/categories';

export interface ICreateRestaurant {
  name: string
  categoryId: string
  city: { name: string; region: string}
  address: string
  averageReceipt: number
  description: string | null
}

export interface ILogoRestaurant {
  restaurantId: string
  file: File
}

export interface IGalleryRestaurant {
  restaurantId: string
  gallery: File[]
}

export interface RestaurantRequest {
  restaurantId: string
}

export const restaurantsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRestaurants: build.mutation<any, {filters: IFiltersRestaurants, pagination: IPagination}>({
      query: (arg) => {
        const cityIds = arg.filters.cities.map( (i: ICity) => i.id )
        const categoryIds = arg.filters.categories.map( (i: ICategory) => i.id )
        const filter = {
          cities: cityIds,
          categories: categoryIds,
          like: arg.filters.like,
          fullness: arg.filters.isFullness
        }
        return {
          url: '/restaurants/filter',
          method: 'GET',
          params: {filters: JSON.stringify(filter), pagination: JSON.stringify(arg.pagination)}
        };
      },
    }),
    getRefreshRestaurant: build.mutation<any, {id: string}>({
      query: (arg) => {
        return {
          url: `/restaurants/${arg.id}`,
          method: 'GET'
        };
      },
    }),
    createRestaurant: build.mutation<RestaurantRequest, Partial<ICreateRestaurant>>({
      query: (credentials: any) => ({
        url: '/restaurants',
        method: 'POST',
        body: credentials,
      })
    }),
    updateRestaurantMain: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/main`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantManagers: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/managers`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantAddress: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/address`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantContacts: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/contacts`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantSocial: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/social`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantHours: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/hours`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantHashtags: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/hashtags`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantGallery: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/gallery`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantAction: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/active`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantDeactive: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/deactive`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantFullness: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/fullness`,
        method: 'PUT',
        body: credentials,
      })
    }),
    updateRestaurantNotFullness: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/restaurants/${credentials.restaurantId}/notfullness`,
        method: 'PUT',
        body: credentials,
      })
    }),
    uploadLogotype: build.mutation<void, Partial<ILogoRestaurant>>({
      query: (credentials: any) => {
        const fd = new FormData();
        fd.append('image', credentials.file, credentials.file.name)

        return {
          url: `/restaurants/${credentials.restaurantId}/logotype`,
          method: 'POST',
          body: fd,
          credentials: 'include',
        }
      }
    }),
    uploadGallery: build.mutation<void, Partial<IGalleryRestaurant>>({
      query: (credentials: any) => {
        const fd = new FormData();
        credentials.gallery.forEach( (image: any) => {
          fd.append('gallery', image, image.name)
        })

        return {
          url: `/restaurants/${credentials.restaurantId}/gallery`,
          method: 'POST',
          body: fd,
          credentials: 'include',
        }
      }
    }),
  }),
})

export const { 
  useGetRestaurantsMutation,
  useGetRefreshRestaurantMutation,
  useCreateRestaurantMutation,
  useUploadLogotypeMutation,
  useUploadGalleryMutation,
  useUpdateRestaurantGalleryMutation,
  useUpdateRestaurantMainMutation,
  useUpdateRestaurantManagersMutation,
  useUpdateRestaurantAddressMutation,
  useUpdateRestaurantContactsMutation,
  useUpdateRestaurantSocialMutation,
  useUpdateRestaurantHoursMutation,
  useUpdateRestaurantHashtagsMutation,
  useUpdateRestaurantActionMutation,
  useUpdateRestaurantDeactiveMutation,
  useUpdateRestaurantFullnessMutation,
  useUpdateRestaurantNotFullnessMutation
} = restaurantsApi;