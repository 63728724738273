// import { ICategory } from "app/interfaces/categories";
import { useGetRestaurantsMutation, useUpdateRestaurantActionMutation, useUpdateRestaurantDeactiveMutation, useUpdateRestaurantFullnessMutation, useUpdateRestaurantNotFullnessMutation } from "app/services/restaurants";
import ActionsColumn from "components/table/action.column";
import ImageColumn from "components/table/image.column";
import { show } from "features/windows/slice";
import { useRestaurants } from "hooks/useRestaurants";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setPage, setSizePage } from "./slice";
import { toDate } from "utils/toDate.util";

interface IProps {
  setElementId: (id: string) => void;
}

export const TableRestaurants: React.FC<IProps> = (props: IProps) => {
  const { setElementId } = props;
  const [ first, setFirst ] = useState(1);
  const [ sizePage, setSizePages ] = useState(10)
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const dispatch = useDispatch();
  const { restaurants, filter, pagination } = useRestaurants()
  
  const [ getRestaurants, { isLoading } ] = useGetRestaurantsMutation()
  const [ actionRestaurant ] = useUpdateRestaurantActionMutation()
  const [ deactionRestaurant ] = useUpdateRestaurantDeactiveMutation()
  
  
  const [ fullnessRestaurant ] = useUpdateRestaurantFullnessMutation()
  const [ notFullnessRestaurant ] = useUpdateRestaurantNotFullnessMutation()
  
  const onRefresh = () => {
    getRestaurants({filters: filter, pagination })
  }

  useEffect(()=> onRefresh(), [filter, pagination]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setSelectedProducts(restaurants.filter((r) => r.isFullness === true ))
  },[restaurants])
  
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleFullness = (id: string, isFullness: boolean) => { 
    if(isFullness) fullnessRestaurant({restaurantId: id}).then( () =>  onRefresh());
    else notFullnessRestaurant({restaurantId: id}).then( () =>  onRefresh());
  }
  const handleActive = (id: string) => actionRestaurant({restaurantId: id}).then( () =>  onRefresh());
  const handleDeactivate = (id: string) => deactionRestaurant({restaurantId: id}).then( () =>  onRefresh());
  const handleViewing = (id: string) => {
    setElementId(id)
    dispatch(show({type: 'restaurants', name: 'edit'}))
  };
  const onPageChange = (event: any) => {
    dispatch(setPage(event.page + 1));
    dispatch(setSizePage(event.rows));
    setFirst(event.first);
    setSizePages(event.rows);
    // onRefresh();
  }
        
  let menu = (item: any) => [
    {
      label: 'Просмотр заведения', 
      icon: 'pi pi-fw pi-pencil', 
      command:()=> handleViewing(item.id),
    },
    {
      label: item.status !== 'active' ? 'Активировать' : 'Деактивировать', 
      icon: item.status !== 'active' ? 'pi pi-fw pi-check-circle' : 'pi pi-fw pi-ban', 
      command:()=> item.status !== 'active' ? handleActive(item.id) : handleDeactivate(item.id),
    }
  ]
        
  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />
  const imageBody = (rowData: any) => <ImageColumn image={`${rowData.logo}`} />
        
  return (
    <>
      <DataTable value={restaurants} 
        scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
        responsiveLayout="stack" breakpoint="768px"
        className="not-selection-btn"
        loading={isLoading}
        selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
        onRowSelect={(e) => handleFullness(e.data.id, true)} onRowUnselect={ (e) => handleFullness(e.data.id, false)}
        selectionMode="checkbox"
      >
        <Column header="Заведение" body={imageBody}></Column>
        <Column field="name" header="Название"></Column>
        <Column field="telephone" header="Телефон"></Column>
        <Column field="status" header="Статус"></Column>
        <Column field="updatedAt" header="Дата изменения" body={(rowData)=>toDate(rowData.createdAt)}></Column>
        <Column field="isFullness" header="Заполнено" selectionMode="multiple" headerStyle={{ width: '3rem' }} ></Column>
        <Column body={action} style={{ maxWidth: '5rem', float: "right" }}></Column>
      </DataTable>
      <Paginator first={first} rows={sizePage} totalRecords={pagination.count} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange}></Paginator>
    </>
  );
}