import React, { useState } from "react";
// import { 
//   showWindow, 
//   createDishes, 
//   getDishesRestaurant 
// } from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { Button } from "primereact/button";
import { composeValidators, requiredValidator } from "components/form/validators";
import { MultiSelectField, SelectField, TextAreaField, TextField } from "components/form/fields";
import SingleUploadImage from "components/upload/single.upload";
// import { IDishes } from "app/interfaces/restaurants";
import { useDispatch } from "react-redux";
import { hide } from "features/windows/slice";
import { useWindows } from "hooks/useWindows";
import { useGetKitchenQuery, useGetCategoriesKitchenQuery, useGetTagsDishesQuery } from "app/services/requisites";
import { useMediaQuery } from "react-responsive";
import { useCreateDishesMutation, useUploadPhotoMutation } from "app/services/kitchen";
// import { refreshAccessToken } from "app/services/tokens";

const formatResponse = (restaurantId: string, data: any, photo: string | null) => {
  const dishes = {
    restaurantId: restaurantId,
    ...data
  }

  dishes.kitchenId = dishes.kitchenId?.id;
  dishes.categoryId = dishes.categoryId?.id;
  dishes.photo = photo ? photo : "";
  dishes.tagIds = dishes?.tagIds ? dishes.tagIds.map((tag: { id: string; }) => tag.id) : [];

  return dishes;
}

interface IProps {
  restaurantId: string;
  refresh?: () => void;
}

export const AddDishesWin: React.FC<IProps> = (props: any) => {
  const { restaurantId, refresh } = props;
  const [ dataForm ] = useState({
    name: "",
    description: "",
    photo: "",
    price: "",
    grams: "",
    categoryId: null,
    kitchenId: null,
    tagIds: []
  })
  const [ file, setFile ] = useState<string | null>(null);
  const [ isLoading, setLoading ] = useState<boolean>(false);
  const dispatch = useDispatch();
  const windows = useWindows();
  const [ createDishes ] = useCreateDishesMutation();
  const [ uploadPhoto ] = useUploadPhotoMutation();

  const handleClose = () => {
    setFile(null)
    dispatch(hide({type: 'kitchen', name: 'add'}))
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    createDishes( formatResponse(restaurantId, values, null) ).unwrap()
      .then( (r: any) => {
        const dishesId = r.dishesId;
        if(file) return uploadPhoto({ dishesId, file })
        return;
      } )
      .then( () => {
        handleClose()
        refresh()
        setLoading(false);
      } )
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const { kitchens } = useGetKitchenQuery(null, {
    selectFromResult: ({ data }) => ({ kitchens: data }),
  });
  const { categories } = useGetCategoriesKitchenQuery(null, {
    selectFromResult: ({ data }) => ({ categories: data }),
  });
  const { tags } = useGetTagsDishesQuery(null, {
    selectFromResult: ({ data }) => ({ tags: data }),
  });

  // const dataForm = {
    
  // }

  return (
    <Dialog 
      header="Создание блюда" 
      visible={windows.kitchen.add} 
      className="col-12 p-0 md:col-10 lg:col-8"
      style={ isMobile ? {
        width: "100vw",
        height: "100vh",
        maxHeight: "100%"
      } : {} }
      onHide={handleClose}
      maximizable={isMobile ? false : true}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={dataForm}
        render={({ handleSubmit }) => (
          
          <form onSubmit={handleSubmit}>
            <div className="col-12 p-0 flex flex-wrap">
              <div className="col-12 md:col-6 p-0">
                <div className="col-12 p-1 flex justify-content-center">
                  <SingleUploadImage label={"Загрузить фотографию"} onSelectFIle={setFile} />
                </div>

                <div className="col-12 p-1">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="kitchenId" label="Категория меню:" render={SelectField}
                    settings={{
                      options: kitchens,
                      optionLabel: "name",
                      filter: true,
                      showClear: true, 
                      filterBy: "name",
                      placeholder: "Выберите категорию"
                    }}
                  />
                </div>

                <div className="col-12 p-1">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="categoryId" label="Категория:" render={SelectField}
                    settings={{
                      options: categories,
                      optionLabel: "name",
                      filter: true,
                      showClear: true, 
                      filterBy: "name",
                      placeholder: "Выберите категорию"
                    }}
                  />
                </div>
              </div>

              <div className="col-12 md:col-6 p-0">

                <div className="col-12 p-1">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="price" label="Цена:" render={TextField}
                    settings={{ placeholder: "Цена за блюдо", keyfilter: /[\d\/]+$/ }}
                  />
                </div>

                <div className="col-12 p-1">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="name" label="Наименование:" render={TextField}
                  />
                </div>

                <div className="col-12 p-1">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="description" label="Описание:" render={TextAreaField}
                  />
                </div>

                <div className="col-12 p-1">
                  <Field
                    name="tagIds" label="Теги:" render={MultiSelectField}
                    settings={{
                      options: tags,
                      optionLabel: "name",
                      filter: true,
                      showClear: true, 
                      filterBy: "name",
                      placeholder: "Выберите необходимые теги",
                      filterPlaceholder: "Поиск по наименованию",
                      emptyFilterMessage: "Нет данных",
                      maxSelectedLabels: 2,
                      resetFilterOnHide: true,
                    }}
                  />
                  
                </div>

              </div>
            </div>
              
              <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
                <div className="col-12 md:col-6 xl:col-3">
                  <Button label="Создать" className="col-12 p-button-raised p-button-success " disabled={isLoading}/>
                </div>
              </div>
            </form>
        )}
      />
    </Dialog>
  );
}

export default AddDishesWin;
