import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { useRestaurants } from "hooks/useRestaurants";
import { hide } from "features/windows/slice";
import { useGetRestaurantsMutation } from "app/services/restaurants";
import { setFilter } from "features/restaurants/slice";
import { ICity } from "app/interfaces/city";
import { ICategory } from "app/interfaces/categories";
import { useGetCitiesQuery } from "app/services/cities";
import { useGetCategoriesRestaurantQuery } from "app/services/requisites";
import { Checkbox } from "primereact/checkbox";

export const FiltersContent: React.FC = (props: any) => {
  const dispatch = useDispatch()
  const { cities } = useGetCitiesQuery(null, {
    selectFromResult: ({ data }) => ({ cities: data }),
  });
  const { categories } = useGetCategoriesRestaurantQuery(null, {
    selectFromResult: ({ data }) => ({ categories: data }),
  });
  
  const [city, setCities] = useState<Array<ICity>>([]);
  const [category, setCategories] = useState<Array<ICategory>>([]);
  const [checkedFull, setCheckedFull] = useState(false);
  const { filter, pagination } = useRestaurants()
  const [ getRestaurants ] = useGetRestaurantsMutation()
  
  useEffect(() => 
    setCities(filter.cities?.length ? filter.cities : []), [filter.cities]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => 
    setCategories(filter.categories?.length ? filter.categories : []), [filter.categories]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => 
    setCheckedFull(!filter.isFullness), [filter.isFullness]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => dispatch(hide({type: 'restaurants', name: 'filter'}));
  const handleApply = () => {
    dispatch(setFilter({cities: city, categories: category, like: filter.like, isFullness: !checkedFull}))
    getRestaurants({filters: filter, pagination })
  };

  return (
    <div className="col-12 p-0">
      <div className="col-12 p-0 flex flex-wrap mt-2 mb-3">
        <div className="col-12 md:col-6">
          <div className="custom-input-text" >
            <label>Город: </label>
            <MultiSelect 
              value={city} 
              options={cities} 
              onChange={(e) => setCities(e.value)} 
              optionLabel="name"
              placeholder="Выберите город"
              showClear
              filter
              filterPlaceholder="Поиск по наименованию"
              maxSelectedLabels={2}
              className="col-12 p-1 custom-multiselect"
              resetFilterOnHide
              emptyFilterMessage="Нет данных"
              selectedItemsLabel={`${city?.length} выбрано элементов`}
            />
          </div>
        </div>

        <div className="col-12 md:col-6">
          <div className="custom-input-text" >
            <label>Категории: </label>
            <MultiSelect 
              value={category} 
              options={categories} 
              onChange={(e) => setCategories(e.value)} 
              optionLabel="name"
              placeholder="Выберите категорию"
              showClear
              filter
              filterPlaceholder="Поиск по наименованию"
              maxSelectedLabels={2}
              className="col-12 p-1 custom-multiselect"
              resetFilterOnHide
              emptyFilterMessage="Нет данных"
              selectedItemsLabel={`${category?.length} выбрано элементов`}
            />
          </div>
        </div>

        <div className='col-12 md:col-6 '>
          <Checkbox
            checked={checkedFull} 
            onChange={e => setCheckedFull(e.checked)}
            inputId="isFullness"
          />
          <label className="pl-2" htmlFor="isFullness">
            Не показывать заполненные
          </label>
        </div>
        
      </div>
      
      <div className="col-12 p-0 flex flex-wrap mt-1 mb-2">
        <div className="col-12 md:col-6">
          <Button label="Отмена" className="col-12 p-button-raised p-button-plain p-button-text " onClick={handleCancel}/>
        </div>
        <div className="col-12 md:col-6">
          <Button label="Применить" className="col-12 p-button-raised p-button-success " onClick={handleApply}/>
        </div>
      </div>
    </div>
  );
}

export default FiltersContent;