import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { api } from './services/api'
import auth from 'features/auth/slice'
import restaurants from 'features/restaurants/slice'
import requisites from 'features/requisites/slice'
import windows from 'features/windows/slice'
import norr from 'features/norr/slice';
import cities from 'features/cities/slice';
import { rtkQueryErrorLogger } from './middleware/handleError'

export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      auth,
      norr,
      cities,
      restaurants,
      requisites,
      windows
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
    ...options,
  })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>