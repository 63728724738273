// import { retry } from '@reduxjs/toolkit/query/react'
import { api } from './api'

export interface User {
  name: string
  phone: string
  role: string
}

export interface UserResponse {
  login: string
  password: string
}

export interface LoginRequest {
  access_token: string
  refresh_token: string
}

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginRequest, Partial<UserResponse>>({
      query: (credentials: any) => ({
        url: '/login',
        method: 'POST',
        body: credentials,
      }),
      transformErrorResponse: (response) => response,
      
      // extraOptions: {
      //   backoff: (t) => {
      //     // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
      //     console.log(t)
      //     retry.fail({ fake: 'error asdasd' })
      //   },
      // },
      invalidatesTags: ['Auth'],
    }),
    currentMe: build.query<User, void>({
      query: () => ({ url: '/account/current' }),
      providesTags: () => [
        { type: 'Auth' },
      ],
    }),
  }),
})

export const { useLoginMutation, useCurrentMeQuery } = authApi;
