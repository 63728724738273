import React from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import ItemKitchen from './item.kitchen';
import { IDishes } from 'app/interfaces/restaurants';
import { useDispatch } from 'react-redux';
import { show } from 'features/windows/slice';

interface IProps {
  items: IDishes[];
  setItem: (dish: IDishes) => void;
}

const DishesMenu: React.FC<IProps> = (props: any) => {
  const { setItem, items } = props;
  const dispatch = useDispatch()

  const handleEdit = (data:IDishes) => {
    setItem(data)
    dispatch(show({type: 'kitchen', name: 'edit'}));
  }

  let data = items.map((item: IDishes ) => {
    return (
      <div className="col-12 p-1" key={item.id}>
        <ItemKitchen item={item} key={item.id} onClick={() => handleEdit(item)}/>
      </div>
    );
  });

  return (
    <div className="card-mini mt-2">
      <ScrollPanel style={{ width: '100%', minHeight: '194px' }} className="custombar ">
        <div className="flex flex-wrap col-12 p-0">
          {data}
        </div>
      </ScrollPanel>
    </div>
  );
};

export default DishesMenu;